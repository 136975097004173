
import { Component, Vue } from 'vue-property-decorator';
import SubModule from '../../components/SubModule.vue';
import { RouteConfig } from 'vue-router';
import routes from './routes';
import { IMenuItem } from '@/components/Menu.vue';
import { store, Getters } from '../../store';
import { UserType } from './json';

@Component
export default class User extends SubModule
{
  private get currentUser(): typeof store[Getters.CURRENT_USER_LOGIN]
  {
    return store[Getters.CURRENT_USER_LOGIN];
  }

protected _getRoutes(): RouteConfig[]
  {
    return routes;
  }

  protected _getDynamicMenuItems(): IMenuItem[]
  {
    const currentUser = this.currentUser;
    const items: IMenuItem[] = [];
    if (currentUser)
    {
      if (currentUser.userType === UserType.CUSTOMER || currentUser.userType === UserType.TRANSLATOR)
      {
        items.push(
          {
            title: this.$t('contact.title').toString(),
            icon: '$vuetify.icons.contact',
            route: `/user/contact`,
          },
        );
      }
    }

    return items;
  }
}
